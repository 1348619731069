.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.canvas-wrapper, #scatter-gl-container {
  display: inline-block;
  vertical-align: top;
}

#scatter-gl-container {
  border: solid 1px black;
  position: relative;
}

/* center the canvas within its wrapper */
#scatter-gl-container canvas {
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  top: 50%;
  position: absolute;
}


.progress-bar{
   -webkit-transition: all 100ms linear;
	-moz-transition: all 100ms linear;
	-ms-transition: all 100ms linear;
	-o-transition: all 100ms linear;
  transition: all 100ms linear;
}



.app-box{
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.gender-box{
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  cursor: pointer;
}

.lazer{
  box-shadow: 0 3px 6px 0 #ffc000;
  background-color: #ffc000;
  height: 4px;
  position: absolute;
  left: 0;
  animation: lazerAnimation 4s infinite;
}

@keyframes lazerAnimation {
  0% {
    top: 0;
  }
  50% {
    
    top: 99%;
  }
  100% {
    
    top: 0;
  }
}
#video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}


.blur-background{
	-webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, .6);
}

.img-instruction{
  background-size: contain !important;
  margin-bottom: 16px;
  margin-top: 16px;
}